<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="名称："><el-input v-model="searchParm.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="型号："><el-input v-model="searchParm.standard"></el-input></el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px">添加参数</el-button></div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px"
						>
							<el-table-column prop="standard" align="center" label="型号" show-overflow-tooltip></el-table-column>
							<el-table-column prop="name" align="center" label="名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="brand" align="center" label="品牌" show-overflow-tooltip></el-table-column>
							<el-table-column prop="ballDiameter" align="center" label="滚动体直径" show-overflow-tooltip></el-table-column>
							<el-table-column prop="ballsNumber" align="center" label="滚动体数目" show-overflow-tooltip></el-table-column>
							<el-table-column prop="pitchDiameter" align="center" label="节圆直径" show-overflow-tooltip></el-table-column>
							<el-table-column prop="contactAngle" align="center" label="接触角" show-overflow-tooltip></el-table-column>
							<el-table-column prop="speed" align="center" label="转速" show-overflow-tooltip></el-table-column>
							<el-table-column prop="bpfi" align="center" label="内环故障频率" show-overflow-tooltip></el-table-column>
							<el-table-column prop="bpfo" align="center" label="外环故障频率" show-overflow-tooltip></el-table-column>
							<el-table-column prop="bsf" align="center" label="滚动体旋转故障频率" show-overflow-tooltip></el-table-column>
							<el-table-column prop="ftf" align="center" label="保持架故障频率" show-overflow-tooltip></el-table-column>
							<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text" @click="editName(scope.row)">编辑</el-button>
									<el-button type="text" @click="remove(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>

		<insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import insert from './insert.vue';
export default {
	components: {
		smCard,
		insert
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			showInsertDialog: false,
			insertObj: {},
			showEditDialog: false,
			editObj: {},
			showCopyInsertDialog: false,
			copyInsertObj: {},
			ents: []
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
			this.list();
		},
		//列表数据
		list() {
			this.$get('/backend-api/comp/type/bearing/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开新增弹窗
		insert() {
			this.insertObj['type'] = 1;
			this.showInsertDialog = true;
		},
		//打开新增弹窗
		editName(row) {
			this.insertObj['row'] = row;
			this.insertObj['type'] = 2;
			this.showInsertDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj['row'] = row;
			this.editObj['type'] = 2;
			this.showEditDialog = true;
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		//删除
		remove(row) {
			this.$confirm('是否确认删除？', '删除轴承参数', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/backend-api/comp/type/bearing/delete', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
